export default {
  app: 'benefitoneindonesia',
  appName: 'Benefit One Indonesia',
  applyBgColorFromDjango : false,
  customRewardCategory: true,
  defaultLanguage: 'id',
  freshchat: {
    isShow: false,
    pages: ''
  },
  footer: {
    dynamicLogo: false
  },
  header: {
    dynamicBg: true,
    dynamicLogo: false,
  },
  home: {
    recommendation: false,
  },

  languageAvailable: ['en', 'id'],
  languageLabel: {
    en: 'English',
    id: 'Indonesia',
  },
  leftNavigate: ['home', 'rewards', 'receipts', 'flexiBenefits'],
  modules: {
    appreciate: false,
    feeds: false,
    leaderboard: false,
    home: true,
    rewards: true,
    settings: true,
    pointsHistory: false,
    calendar: false,
  },
  mobileTopup: {
    mobileNumberMaxDigit: 14,
    mobileNumberMinDigit: 9,
  },
  rewards: {
    filters: {
      MAX_POINTS: 1000000,
    },
  },
  topMenu: true,
};
